import moment from "moment";
import HeadNav from "../../components/head-nav/index.vue";

// 订单详情
import req_detail_data from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_queryHotelOrderDetails";
import consumer_t_od_hotel_order_interim_getRefundResult from '@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_getRefundResult';
// 退订
import req_refund from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_applyCancelOrder";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
import HotelRefund from "@/component/hotelRefund/index.vue";
export default {
  data() {
    return {
      loading: false,
      detail: {
        personList: [],
        order: {
          refundRule: {},
        },
        evection: {},
      },
      isGetResult: false,
      intervalFun: null,
      // checkList: [],
    };
  },
  computed: {

  },
  components: {
    HeadNav,
    HotelRefund,
  },
  methods: {
    req_detail() {
      this.loading = true;
      req_detail_data({ orderNo: this.$route.query.orderNo }).then((res) => {
        res.orderInterimResult.refundRule = JSON.parse(res.orderInterimResult.refundRule);
        this.detail = {
          personList: res.orderPersonList,
          order: res.orderInterimResult,
        };
        this.detail.order.paymentChanelText = this.detail.order.paymentChanelText ? this.detail.order.paymentChanelText : '---';
        this.loading = false;
      });
    },
    refundPromise() {
      return new Promise(resolve => {
        this.$confirm('确定退订该订单?', '申请退订', {
          confirmButtonText: '确定退订',
          cancelButtonText: '我再想想',
          type: 'warning'
        }).then(() => {
          resolve(true);
        }).catch(() => {
          resolve(false);
        });
      })
    },
    async refund(orderNo, evectionNo) {
      let params = { orderNo, evectionNo, cancelRemark: '' };
      this.$refs.aHotelRefund.clickUnsubscribe(params).then(res=>{

      });
    },
    getResult() {
      if (!this.isGetResult) {
        return
      }
      // 轮询方法
      let params = {
        orderNo: this.$route.query.orderNo,
      };
      consumer_t_od_hotel_order_interim_getRefundResult(params).then(res=>{
        if (!this.isGetResult) {
          return;
        }
        // 订单状态 13：退订失败 16：退订成功
        let status = res.datas.status;
        switch (status) {
          case 16:
            // 成功
            console.log('chengong');
            this.isGetResult = false;
              this.loading = false;
            clearTimeout(this.intervalFun);
            // 跳转
              this.$message.success('退订成功');
              setTimeout(()=>{
                this.req_detail();
              }, 3000)
            break;
          case 13:
            // 失败
            console.log('shibai');
              this.loading = false;
            this.isGetResult = false;
            clearTimeout(this.intervalFun);
            this.$alert('退订失败，有问题请联系客服', '申请退订', {
              confirmButtonText: '知道了',
              callback: action => {}
            });
            break;
          default:
            // 继续轮询
            setTimeout(()=>{
              this.getResult();
            }, 200)
        }
      },error=>{
        this.loading = false;
      }).catch(e=>{
        this.loading = false;
      })
    },
    to_list() {
      this.$router.back();
    },
    resultForSetInterval() {
      this.intervalFun = setTimeout(()=>{
        console.log('jieguo');
        this.loading = false;
        this.isGetResult = false;
        // 弹出等待结果弹窗
        this.$alert('已提交申请，请耐心等待退订结果', '申请退订', {
          confirmButtonText: '知道了',
          callback: action => {
            this.req_detail();
          }
        });
      }, 2000);
    },
  },

  created() {},
  mounted() {

  },
  activated() {
    this.req_detail();
  },
  deactivated() {
    this.isGetResult = false;
    clearTimeout(this.intervalFun);
  },
  destroyed() {},
  watch: {},
  filters: {
    format_time(val) {
      return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
    },
    format_create(val) {
      switch (Number(val)) {
        case 1:
          return "个人创建";
          break;
        case 2:
          return "出差单创建";
          break;
      }
    },
    format_status(val, cancelStatus) {
      if (cancelStatus === -1) {
        switch (Number(val)) {
          case 0:
            return "待支付";
            break;
          case 1:
            return "支付中";
            break;
          case 2:
            return "待出房";
            break;
          case 4:
            return "待入住";
            break;
          case 6:
            return "已取消";
            break;
          case 7:
            return "取消中";
            break;
          case 8:
            return "取消失败";
            break;
          case 9:
            return "预下单";
            break;
          case 10:
            return "创建订单失败";
            break;
          case 11:
            return "已出房";
            break;
          case 12:
            return "已退房";
            break;
          case 13:
            return "退房失败";
            break;
          case 14:
            return "出房失败";
            break;
          case 15:
            return "退房中";
            break;
          case -1:
            return "已删除";
            break;
        }
      } else {
        switch (Number(cancelStatus)) {
          case 0:
            return "退订审核中";
            break;
          case 1:
            return "退订成功";
            break;
          case 2:
            return "待平台确认";
            break;
          case 4:
            return "退订失败";
            break;
        }
      }
    },
    format_payType(val) {
      switch (Number(val)) {
        case 1:
          return "个人支付";
          break;
        case 2:
          return "企业支付";
          break;
      }
    },
    format_week(val) {
      if (val) {
        switch (moment(val).day()) {
          case 1:
            return "周一";
            break;
          case 2:
            return "周二";
            break;
          case 3:
            return "周三";
            break;
          case 4:
            return "周四";
            break;
          case 5:
            return "周五";
            break;
          case 6:
            return "周六";
            break;
          case 0:
            return "周日";
            break;
        }
      } else {
        return "";
      }
    },
  },
};
