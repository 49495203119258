import consumer_t_od_hotel_order_interim_getRefundResult from '@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_getRefundResult';
import consumer_t_od_hotel_order_interim_applyCancelOrder from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_applyCancelOrder";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
export default {
    data() {
        return {
            commonResolve: null,
            commonReject: null,
            isGetResult: false,
            intervalFun: null,
        }
    },
    props: {},
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
        this.isGetResult = false;
        clearTimeout(this.intervalFun);
    },
    destroyed() {
        this.isGetResult = false;
        clearTimeout(this.intervalFun);
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        refundPromise() {
            return new Promise((resolve, reject) => {
                this.$confirm('确定退订该订单?', '申请退订', {
                    confirmButtonText: '确定退订',
                    cancelButtonText: '我再想想',
                    type: 'warning'
                }).then(() => {
                    console.log('11')
                    resolve(true);
                }).catch(() => {
                    console.log('22');
                    resolve(false);
                });
            })
        },
        getResult(params) {
            if (!this.isGetResult) {
                return
            }
            // 轮询方法
            consumer_t_od_hotel_order_interim_getRefundResult(params).then(res=>{
                if (!this.isGetResult) {
                    return;
                }
                // 订单状态 13：退订失败 16：退订成功
                let status = res.datas.status;
                switch (status) {
                    case 16:
                        // 成功
                        console.log('chengong');
                        this.isGetResult = false;
                        clearTimeout(this.intervalFun);
                        // 跳转
                        this.$message.success('退订成功');
                        this.commonResolve(true);
                        break;
                    case 13:
                        // 失败
                        console.log('shibai');
                        this.isGetResult = false;
                        clearTimeout(this.intervalFun);
                        this.$alert('退订失败，有问题请联系客服', '申请退订', {
                            confirmButtonText: '知道了',
                            callback: action => {
                                this.commonResolve(false);
                            }
                        });
                        break;
                    default:
                        // 继续轮询
                        setTimeout(()=>{
                            this.getResult(params);
                        }, 200)
                }
            },error=>{
                this.commonResolve(false);
            }).catch(e=>{
                this.commonResolve(false);
            })
        },
        resultForSetInterval() {
            this.intervalFun = setTimeout(()=>{
                console.log('jieguo');
                this.isGetResult = false;
                // 弹出等待结果弹窗
                this.$alert('已提交申请，请耐心等待退订结果', '申请退订', {
                    confirmButtonText: '知道了',
                    callback: action => {
                        this.commonResolve(true);
                    }
                });
            }, 2000);
        },
        clickUnsubscribe(params) {
            return new Promise(async (resolve, reject) => {
                this.commonResolve = resolve;
                this.commonReject = reject;
                // 二次确认
                let promiseResult = await this.refundPromise();
                if (!promiseResult) {
                    resolve(false);
                    return;
                }
                let [err, res] = await awaitWrap(consumer_t_od_hotel_order_interim_applyCancelOrder(params));
                if (err) {
                    this.$message.warning(err.data.msg);
                    resolve(false)
                    return;
                }
                // 开始轮询， 2秒后结束
                this.isGetResult = true;
                let i = 0;
                /*setInterval(()=>{
                    console.log(i++)
                }, 1000)*/
                this.getResult(params);
                this.resultForSetInterval();
            })
        }
    }
}